import set from 'lodash/set';

import fixExcerpt from '@sp/json-schema/scripts/fix/mutators/blog/utils/fixExcerpt';
import getPrepareContent from '@sp/json-schema/scripts/fix/mutators/blog/utils/getPrepareContent';

// eslint-disable-next-line import/prefer-default-export
export const convertToTree = (data = {}) => Object
  .keys(data)
  .reduce((acc, curr) => set(acc, curr, data[curr]), {});

/**
 * Check is content correct
 * @param {string} content
 * @returns {boolean}
 */
export const checkIsContentCorrect = (content) => {
  if (!content) return false;

  try {
    const data = JSON.parse(content);

    return typeof data === 'object';
  } catch {
    return false;
  }
};

/**
 * Create a Slate value from a string
 * @param {string} text
 * @returns {[{children: [{text: string}], type: string}]}
 */
export const createValueFromString = (text = '') => [{
  type: 'paragraph',
  children: [{ text }],
}];

/**
 * Get excerpt data
 * @param {string} str
 * @returns {array}
 */
export const getExcerptData = (str) => {
  const isCorrectContent = checkIsContentCorrect(str);

  return isCorrectContent
    ? fixExcerpt(getPrepareContent(JSON.parse(str)))
    : createValueFromString(str);
};
